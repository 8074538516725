export function primaryToSecondary(hslPrimary: number[]): number[] {
  let [h, s, l] = hslPrimary;
  h = Math.min(h + 4, 360);
  s = Math.min(s + 19, 100);
  l = Math.min(l + 9, 100);
  return [h, s, l];
}

export function hexToHsl(hex: string): number[] {
  hex = hex.replace(/^#/, "");

  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);

  let l = (max + min) / 2;

  let s = 0;
  if (max !== min) {
    s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);
  }

  let h = 0;
  if (max === r) {
    h = (g - b) / (max - min);
  } else if (max === g) {
    h = 2 + (b - r) / (max - min);
  } else if (max === b) {
    h = 4 + (r - g) / (max - min);
  }

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  return [h, Math.round(s * 100), Math.round(l * 100)];
}

export function hslToHex(h: number, s: number, l: number) {
  s /= 100;
  l /= 100;

  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    return l - a * Math.max(-1, Math.min(k - 3, Math.min(9 - k, 1)));
  };

  const r = Math.round(f(0) * 255);
  const g = Math.round(f(8) * 255);
  const b = Math.round(f(4) * 255);

  return `#${((1 << 24) | (r << 16) | (g << 8) | b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}
