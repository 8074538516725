<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.384 14.0286C8.86629 14.0286 9.20771 13.6137 9.45543 12.9709L13.8417 1.51314C13.9623 1.20514 14.036 0.930571 14.036 0.702857C14.036 0.267714 13.7614 0 13.326 0C13.0983 0 12.8237 0.0668571 12.5157 0.187429L0.998 4.6C0.435143 4.81429 0 5.15571 0 5.64457C0 6.26057 0.468857 6.46829 1.11171 6.66257L4.73457 7.76057C5.15629 7.89486 5.39743 7.88114 5.68543 7.61343L13.0311 0.749429C13.1183 0.669143 13.2189 0.682572 13.286 0.742857C13.3529 0.809714 13.3594 0.910286 13.2791 0.997143L6.442 8.37C6.18771 8.64457 6.16086 8.87229 6.29486 9.32086L7.35286 12.8566C7.55371 13.5329 7.76143 14.0286 8.38429 14.0286"
      fill="white"
    />
  </svg>
</template>
