<template>
  <svg
    width="181"
    height="154"
    viewBox="0 0 181 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4963 31.4437C28.9039 14.1816 43.7621 6.66916 59.3859 8.99775L59.419 9.00489C60.9813 9.23812 62.5302 9.56874 64.0519 9.98988L83.993 14.2303L82.0951 21.2016C84.1578 23.5559 85.9586 26.2316 87.4297 29.2217C95.6341 20.4229 108.603 17.6856 120.637 20.4382L143.285 25.3289L138.033 41.6836L124.348 39.8777L87.2204 139.257L64.6046 134.374C64.6046 134.374 -1.41476 76.4483 20.4963 31.4437Z"
      fill="#FF98DA"
      stroke="black"
      stroke-width="1.4123"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M110.047 34.1075C96.5995 6.76571 55.4605 5.78037 40.8265 35.8356C18.9155 80.8402 87.2149 139.258 87.2149 139.258C87.2149 139.258 161.004 120.438 171.517 74.4065C182.029 28.3789 130.795 11.8512 110.047 34.1075Z"
      fill="#F5CDD5"
      stroke="black"
      stroke-width="1.4123"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M89.1055 79.2852C89.1055 79.2852 89.9045 87.0278 98.6483 88.885C106.369 90.5252 111.302 84.0781 111.302 84.0781"
      stroke="black"
      stroke-width="1.4123"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M133.153 90.8728C135.153 91.3048 137.21 89.6374 137.748 87.1487C138.285 84.6599 137.099 82.2922 135.099 81.8603C133.098 81.4283 131.041 83.0957 130.504 85.5844C129.966 88.0731 131.152 90.4408 133.153 90.8728Z"
      fill="black"
    />
    <path
      d="M66.3948 76.4548C68.3953 76.8868 70.4527 75.2195 70.9901 72.7307C71.5275 70.242 70.3414 67.8743 68.3409 67.4423C66.3405 67.0103 64.2831 68.6777 63.7457 71.1664C63.2083 73.6552 64.3944 76.0229 66.3948 76.4548Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "CustomSmileWithHeart",
};
</script>
